import React from 'react';
import { styled } from 'styled-components';

interface ModalPropsType {
  contentShowHandler: () => void;
}
const Modal = (props: ModalPropsType) => {
  const { contentShowHandler } = props;
  return (
    <ModalFrame>
      <CloseModal onClick={contentShowHandler}>X</CloseModal>
      <div>
        <p className="Title">서비스 이용약관</p>
      </div>
      <ModalContent>
        <div>
          <p className="subTitle">&lt;서비스 이용약관 &gt;</p>
          <p>1. 약관의 적용</p>
          <p>
            2. 1-1. 본 서비스 이용 약관&#40;이하 &#34;약관&#34;&#41;은
            캣핸드&#40;이하 &#34;회사&#34;라 칭함&#41;이 제공하는 코리포리 웹
            서비스&#40;이하 &#34;서비스&#34;&#41;의 이용 조건 및 규정을
            명시합니다.
          </p>
          <p>
            3. 1-2. 본 약관은 회원 가입 시 동의한 것으로 간주되며, 서비스 이용
            시 회원에게 적용됩니다.
          </p>
          <p>4.회원 가입</p>
          <p>
            5. 2-1. 회원 가입은 실명, 이메일 주소, 비밀번호를 포함한 필수 정보를
            제공하여 완료됩니다.
          </p>
          <p>
            6. 2-2. 회원은 제공한 정보의 정확성을 유지하고, 회사는 회원 정보를
            보호하기 위해 노력합니다.
          </p>
          <p>7.서비스 이용</p>
          <p>8. 3-1. 회원은 서비스를 본래 용도에 맞게 사용해야 합니다. </p>
          <p>
            9.3-2. 회원은 다른 회원의 개인 정보 및 서비스 내의 콘텐츠를 무단으로
            수집, 전송, 게시하거나 공유해서는 안됩니다.
          </p>
          <p>10.개인 정보 보호</p>
          <p>
            11.4-1. 회사는 회원의 개인 정보를 적절히 보호하며, 정보의 수집 및
            이용은 개인 정보 보호 정책에 따릅니다.
          </p>
          <p> 12.계정 보안</p>
          <p>
            13.5-1. 회원은 자신의 계정을 안전하게 보관해야 하며, 계정 정보의
            유출로 인한 문제는 회원의 책임입니다.
          </p>
          <p>
            14.5-2. 계정에 불법적인 접근이 의심될 경우, 회원은 즉시 회사에
            알려야 합니다.
          </p>
          <p>15.서비스 변경 및 중단</p>
          <p>
            16. 6-1. 회사는 사전 고지 후 서비스의 내용을 변경하거나 중단할 수
            있습니다.
          </p>
          <p>17.면책 조항</p>
          <p>
            18. 7-1. 회사는 서비스 이용으로 발생한 손실이나 피해에 대해 일체의
            책임을 지지 않습니다.
          </p>
          <p>19.약관의 변경</p>
          <p>
            20. 8-1. 회사는 필요한 경우 약관을 변경할 수 있으며, 변경된 약관은
            서비스 내 공지로 효력을 발생합니다.
          </p>
          <p>21.준거법과 재판관할</p>
          <p>
            22.9-1. 본 약관은 대한민국 법률에 따라 해석되며, 서비스 이용으로
            인한 분쟁의 소송은 대한민국 법원을 관할합니다.
          </p>
          <p>23.문의처</p>
          <p>
            10-1. 본 약관 또는 서비스에 대한 문의는 대표메일
            caat.hand@gmail.com로 문의하실 수 있습니다. 이상의 약관은 2024년 4월
            10일에 최종 수정되었습니다.
          </p>
        </div>

        <div>
          <p className="subTitle">&lt;개인정보 수집 및 이용 약관&gt;</p>
          <p>1. 수집하는 개인 정보 항목</p>
          <p>
            2. 1-1. 캣핸드(이하 &quot;회사 &quot;라 칭함)은 다음과 같은 개인
            정보를 수집합니다.
          </p>
          <li>이메일 주소, 생일, 국가, 기타 회원 가입 시 필요한 정보</li>
          <p>3.개인 정보 수집 및 이용 목적</p>
          <p>4.2-1. 회사는 수집한 개인 정보를 다음 목적으로 이용합니다.</p>
          <li>회원 가입 및 관리</li>
          <li>서비스 제공 및 개선</li>
          <li>이벤트 및 프로모션 안내</li>
          <li>고객 지원 및 문의 응대</li>
          <li>법령 및 이용 약관 위반 조사 및 대응</li>
          <p>5. 개인 정보의 보유 및 이용 기간</p>
          <p>
            6 .3-1. 회사는 회원 탈퇴 또는 개인 정보 수집 및 이용 목적 달성
            시까지 개인 정보를 보유하며, 해당 기간 이후에는 즉시 파기됩니다.
          </p>
          <p>7. 개인 정보의 파기 절차 및 방법</p>
          <p>
            8. 4-1. 개인 정보는 수집 및 이용 목적이 달성된 경우, 또는 회원 탈퇴
            요청 시에 안전하게 파기됩니다.
          </p>
          <p>
            9. 4-2. 파기된 개인 정보는 기록, 전자적 파일 등의 형태로 남지 않도록
            파기됩니다.
          </p>
          <p>10. 개인 정보의 제공 및 공유</p>
          <p>
            11. 5-1. 회사는 회원의 동의 없이 개인 정보를 타 기업이나 제3자와
            공유하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
          </p>
          <li>법령의 규정에 따른 경우</li>
          <li>회원의 동의가 있을 경우</li>
          <p>12. 개인 정보의 안전성 확보 조치</p>
          <p>
            13. 6-1. 회사는 개인 정보의 안전성을 확보하기 위해 다음과 같은
            조치를 취합니다.
          </p>
          <li>개인 정보에 대한 접근 제한</li>
          <p>14. 개인정보 열람, 정정 및 삭제 권리</p>
          <p>
            15. 7-1. 회원은 언제든지 자신의 개인 정보를 열람, 정정, 삭제할 수
            있습니다. 자세한 내용은 개인 정보 보호 정책을 참고하시기 바랍니다.
          </p>
          <p>16. 개인 정보 보호 책임자 및 문의처</p>
          <p>
            17. 8-1. 개인 정보 보호 책임자는 김이름이며, 개인 정보와 관련된 문의
            및 불만 사항은 대표메일 caat.hand@gmail.com 또는 대표전화
            1234-1234로 연락하여 주십시오.
          </p>
          <p>18. 약관의 변경</p>
          <p>
            19. 9-1. 회사는 필요한 경우 개인정보 수집 및 이용 약관을 변경할 수
            있으며, 변경된 약관은 서비스 내 공지로 효력을 발생합니다. 이상의
            약관은 2024년 4월 10일에 최종 수정되었습니다.
          </p>
        </div>

        <div>
          <p className="subTitle">&lt; 위치기반서비스 이용약관 &gt;</p>
          <p>1. 약관의 적용</p>
          <p>
            2. 1-1. 본 위치기반서비스 이용약관&#40;이하 &quot;약관&quot;&#41;은
            캣핸드&#40;이하 &quot;회사&quot;라 칭함&#41;이 제공하는
            위치기반서비스&#40;이하 &quot;서비스&quot;&#41;의 이용 조건 및
            규정을 명시합니다.
          </p>
          <p>
            3. 1-2. 본 약관은 회원 가입 시 동의한 것으로 간주되며, 서비스 이용
            시 회원에게 적용됩니다.
          </p>
          <p>4. 위치정보의 수집 및 이용 목적</p>
          <p>
            5. 2-1. 회사는 서비스 제공을 위해 회원의 위치정보를 수집하며, 다음
            목적으로 이용합니다.
          </p>
          <li>근처 관광지 정보 제공 및 서비스의 맞춤화</li>
          <li>서비스 이용에 필요한 지역 기반 정보 제공</li>
          <p>6. 수집하는 위치정보 항목</p>
          <p>7. 3-1. 회사는 다음과 같은 위치정보를 수집합니다.</p>
          <li>GPS 정보</li>
          <p>8. 위치정보 수집 방법</p>
          <p>
            9. 4-1. 회사는 위치정보를 사용자로부터 수집하며, 해당 정보는 서비스
            이용 시 자동으로 수집됩니다.
          </p>
          <p>10. 위치정보의 보유 및 이용 기간</p>
          <p>
            11. 5-1. 회사는 위치정보의 수집 목적이 달성된 경우 또는 회원 탈퇴
            요청 시에 해당 정보를 즉시 파기합니다.
          </p>
          <p>12. 위치정보 제공 및 공유</p>
          <p>
            13. 6-1. 회사는 위치정보를 회원 동의 없이 타 기업이나 제3자와
            공유하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
          </p>
          <li>법령의 규정에 따른 경우</li>
          <li>회원의 동의가 있을 경우</li>
          <p>14. 회원의 권리와 의무</p>
          <p>
            15.7-1. 회원은 언제든지 위치기반서비스 이용을 거부할 수 있으나, 전체
            서비스 이용에 어려움이 있을 수 있습니다.
          </p>
          <p>
            16. 7-2. 회원은 자신의 위치정보를 정확하게 제공해야 하며, 부정확한
            정보 제공으로 발생한 문제에 대한 책임은 회원에게 있습니다.
          </p>
          <p>17. 약관의 변경</p>
          <p>
            18. 8-1. 회사는 필요한 경우 위치기반서비스 이용약관을 변경할 수
            있으며, 변경된 약관은 서비스 내 공지로 효력을 발생합니다.
          </p>
          <p>19. 면책 조항</p>
          <p>
            20. 9-1. 회사는 위치정보 이용으로 발생한 손실이나 피해에 대해 일체의
            책임을 지지 않습니다.
          </p>
          <p>21. 준거법과 재판관할</p>
          <p>
            22. 10-1. 본 약관은 대한민국 법률에 따라 해석되며, 위치기반서비스
            이용으로 인한 분쟁의 소송은 대한민국 법원을 관할합니다.
          </p>
          <p>23. 문의처</p>
          <p>
            24. 11-1. 본 약관 또는 서비스에 대한 문의는 대표메일
            caat.hand@gmail.com로 문의하실 수 있습니다. 이상의 약관은 2024년 4월
            10일에 최종 수정되었습니다.
          </p>
        </div>

        <div>
          <p className="subTitle">&lt; 개인정보처리방침 &gt;</p>
          <p>1. 개인정보의 처리 목적</p>
          <p>
            2. 1-1. 캣핸드&#40;이하 &quot;회사&quot;라 칭함&#41;는 다음의 목적을
            위하여 개인정보를 처리합니다.
          </p>
          <li>회원 가입 및 관리</li>
          <li>서비스 제공 및 운영</li>
          <li>이벤트 및 프로모션 참여 및 안내</li>
          <li>서비스 개선 및 신규 서비스 개발</li>
          <li>법령 및 이용 약관 위반 조사 및 대응</li>
          <p>3. 개인정보의 처리 및 보유 기간</p>
          <p>
            4. 2-1. 회사는 개인정보를 처리 목적 달성 시까지 또는 회원의 동의
            철회 시까지 보유하며, 보유 기간 종료 시에는 지체 없이 파기합니다.
          </p>
          <p>5. 개인정보의 제3자 제공</p>
          <p>
            6. 3-1. 회사는 회원의 동의 없이 개인정보를 외부에 제공하지 않습니다.
            다만, 아래의 경우에는 예외로 합니다.
          </p>
          <li>법령의 규정에 따른 경우</li>
          <li>회원의 동의가 있을 경우</li>
          <p>7. 개인정보의 처리 위탁</p>
          <p>
            8. 4-1. 회사는 서비스 향상을 위해 개인정보 처리를 외부 업체에 위탁할
            수 있으며, 이 경우에는 위탁 업체에 대한 책임을 집니다.
          </p>
          <p>9. 이용자의 권리와 의무</p>
          <p>
            10. 5-1. 이용자는 자신의 개인정보에 대해 언제든지 열람, 정정, 삭제를
            요청할 수 있습니다.
          </p>
          <p>
            11. 5-2. 이용자는 개인정보를 제공할 때 정확한 정보를 제공해야 하며,
            부정확한 정보로 발생한 문제에 대한 책임은 이용자에게 있습니다.
          </p>
          <p>12. 개인정보의 안전성 확보 조치</p>
          <p>
            13. 6-1. 회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를
            취합니다.
          </p>
          <li>개인정보에 대한 접근 제한</li>
          <p>14. 개인정보 보호책임자 및 연락처</p>
          <p>
            15. 7-1. 개인 정보 보호 책임자는 김이름이며, 개인 정보와 관련된 문의
            및 불만 사항은 대표메일 caat.hand@gmail.com로 연락하여 주십시오.
          </p>
          <p>16. 개인정보 처리방침의 변경</p>
          <p>
            17. 8-1. 회사는 필요한 경우 개인정보처리방침을 변경할 수 있으며,
            변경된 방침은 서비스 내 공지로 효력을 발생합니다.
          </p>
          <p>18. 문의처</p>
          <p>
            19. 9-1. 본 개인정보처리방침 또는 서비스에 대한 문의는 대표메일
            caat.hand@gmail.com로 문의하실 수 있습니다.
          </p>
          <p>
            이상의 개인정보처리방침은 2024년 4월 10일에 최종 수정되었습니다.
          </p>
        </div>

        <div>
          <p className="subTitle">&lt; 이용 안내 &gt;</p>
          <p>1. 회원 가입</p>
          <li>서비스를 이용하려면 먼저 회원으로 가입해야 합니다.</li>
          <li>
            필요한 개인 정보를 정확히 입력하고, 회원 약관 및 개인정보처리방침에
            동의해주세요.
          </li>
          <p>2. 서비스 이용</p>
          <li>회원 가입이 완료되면 서비스를 자유롭게 이용할 수 있습니다.</li>
          <li>다양한 기능과 콘텐츠를 즐기며 서울 내 여행 계획을 세워보세요.</li>
          <p>3.위치기반서비스 이용</p>
          <li>
            서비스 이용 시 위치기반 정보를 활용하여 근처 관광지 정보를 편리하게
            확인할 수 있습니다.
          </li>
          <li>
            위치 정보 제공을 허용하면 더욱 정확하고 맞춤화된 서비스를 이용할 수
            있습니다.
          </li>
          <p>4. 개인 정보 보호</p>
          <li>
            회원 정보는 안전하게 보호되며, 개인정보처리방침에 따라 적절한 관리가
            이루어집니다.
          </li>
          <li>자세한 내용은 개인정보처리방침을 참고해주세요.</li>
          <p>5.이벤트 및 프로모션</p>
          <li>다양한 이벤트와 프로모션을 통해 특별한 혜택을 누리세요.</li>
          <li>참여 방법과 유의사항은 이벤트 페이지에서 확인 가능합니다.</li>
          <p>6.고객 지원</p>
          <li>
            서비스 이용 중 궁금한 사항이나 문제가 발생하면 언제든지 고객
            지원팀에 문의해주세요.
          </li>
          <li>문의는 대표메일 caat.hand@gmail.com를 통해 가능합니다.</li>
          <p>7.서비스 변경 및 업데이트</p>
          <li>
            회사는 서비스를 지속적으로 개선하며, 필요한 경우 내용을 변경하거나
            업데이트할 수 있습니다.
          </li>
          <li>변경사항은 서비스 내 공지로 알려집니다.</li>
          <p>8. 약관 및 정책 확인</p>
          <li>서비스 이용 시 약관 및 정책을 준수해야 합니다.</li>
          <li>
            이용약관, 개인정보처리방침 등은 언제든지 확인 가능하며, 변경사항이
            있는 경우 공지됩니다.
          </li>
          <p>이상의 이용 안내는 2024년 4월 10일에 최종 수정되었습니다.</p>
        </div>
      </ModalContent>
    </ModalFrame>
  );
};

export default Modal;

const ModalFrame = styled.div`
  position: absolute;
  width: 1289px;
  height: 950px;
  border: 1px solid #d2d2d2;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  background-color: white;
  font-size: 15pt;
  color: #272727;
  padding: 30px;
  .Title {
    font-size: 24pt;
    font-weight: bold;
  }
`;
const CloseModal = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
`;
const ModalContent = styled.div`
  text-align: start;
  height: 840px;
  overflow-y: scroll;
  div {
    padding: 35px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    .subTitle {
      margin-bottom: 10px;
    }
    li {
      margin-left: 30px;
    }
  }
`;
